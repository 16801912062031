import Page from '../../components/Page';
import './style.scss';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { paramCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {

  Tab,
  Tabs,
  Card,
  Table,

  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,

} from '@mui/material';
import { Stack, InputAdornment, TextField, MenuItem } from '@mui/material';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { emptyRows } from '../../hooks/useTable';
// @types
import { UserManager } from '../../@types/user';
// _mock_
import { _userList } from '../../_mock';
// components
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import {
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedActions,
} from '../../components/table';
// sections
import { UserTableRow } from '../../sections/@dashboard/user/list';
import UserTableRowRanges from 'src/sections/@dashboard/user/list/UserTableRowRanges';

const STATUS_OPTIONS = ['buscar'];

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'company', label: 'Company', align: 'left' },
  { id: 'role', label: 'Role', align: 'left' },
  { id: 'isVerified', label: 'Verified', align: 'center' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: '' },
];

export default function Ranges() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState(_userList);

  const [filterName, setFilterName] = useState('');

  const [tableDataUsers, setTableDataUsers] = useState([]);
  const [tableDays, setDays] = useState([]);

  const [mailUser, setMailUser] = useState('');

  const [filterRole, setFilterRole] = useState('buscar');

  const [isNotFound, setIsNotFound] = useState(true);

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('buscar');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = (id: string) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);
  };

  const handleDeleteRows = (selected: string[]) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (id: string) => {
    navigate(PATH_DASHBOARD.user.edit(paramCase(id)));
  };

  const dataFiltered = []
  const denseHeight = dense ? 52 : 72;



  //Flags
  const [flags, setFlags] = useState<any>([]);

  const [start, setStart] = useState<any>("");
  const [end, setEnd] = useState<any>("");

  const filterUser = () => {
    console.log(mailUser)
  }

  const limit_deposit = async (id: string) => {
    await axios.post('https://arbety.eway.dev:3000/api/admin/limit', { userId: id, limitation: 2 });
    getnewusers();
  };

  const limit_withdraw = async (id: string) => {
    await axios.post('https://arbety.eway.dev:3000/api/admin/limit', { userId: id, limitation: 1 });
    getnewusers();
  };

  const limit_bet = async (id: string) => {
    await axios.post('https://arbety.eway.dev:3000/api/admin/limit', { userId: id, limitation: 3 });
    getnewusers();
  };

  const getnewusers = async () => {
    if (start && end) {
      let res = await axios.post('https://arbety.eway.dev:3000/api/admin/rangeds', {
        "start": start,
        "end": end
      });
      let users: any = []
      res.data.results.forEach((r: any) => {
        r.days = res.data.days
        users.push(r)
      })
      setTableDataUsers(users);
      setDays(res.data.days);

      if (res.data.length > 0) {
        setIsNotFound(false)
      }
      else {
        setIsNotFound(true)
      }
    }


  }

  useEffect(() => {
    return () => {
    };
  }, []);


  return (
    <Page title="General: Limits" className='center-container'>
      <Container maxWidth={themeStretch ? false : 'lg'}>


        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <Divider />

          <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
            <TextField
              type='time'
              fullWidth
              value={start}
              onChange={(event) => {
                setStart(event.target.value)
              }}
              placeholder="Horário inicial"
            />

            <TextField
              type='time'
              fullWidth
              value={end}
              onChange={(event) => {
                setEnd(event.target.value)
              }}
              placeholder="Horário final"
            />

            <IconButton onClick={getnewusers}>
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </IconButton>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}

              <Table size={dense ? 'small' : 'medium'}>
                <TableHead >
                  <TableRow>
                    <TableCell
                      align={'left'}
                      sortDirection={false}
                    >
                      User ID
                    </TableCell>

                    {
                      tableDays.map((day: any) => (
                        <TableCell
                          key={day}
                          align={'left'}
                          sortDirection={false}
                        >
                          {day} (Qnt)
                        </TableCell>
                      ))
                    }

                    <TableCell
                      align={'left'}
                      sortDirection={false}
                    >
                      Total de apostas
                    </TableCell>

                  </TableRow>
                </TableHead>

                <TableBody>
                  {tableDataUsers
                    .map((row: any) => (
                      <UserTableRowRanges
                        key={row.userId}
                        row={row}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {/* <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[1]}
              component="div"
              count={1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box> */}
        </Card>
      </Container>
    </Page>
  );
}

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  tableData: UserManager[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'buscar') {
    tableData = tableData.filter((item: Record<string, any>) => item.status === filterStatus);
  }

  if (filterRole !== 'buscar') {
    tableData = tableData.filter((item: Record<string, any>) => item.role === filterRole);
  }

  return tableData;
}
