import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, Checkbox, TableRow, TableCell, Typography, MenuItem } from '@mui/material';
// @types
import { UserManager } from '../../../../@types/user';
// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import axios from 'axios';

// ----------------------------------------------------------------------

type Props = {
  row: any;
};

export default function UserTableRowRanges({
  row
}: Props) {
  const theme = useTheme();

  var row: any;

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };


  return (
    <TableRow hover>
      <TableCell align="left">{row.userId}</TableCell>

      {
        row.days.map((d: any) => (
          <TableCell key={d} align="left">


            {row[d] &&
              <div>{row[d]}</div>
            }

            {!row[d] &&
              <div>0</div>
            }



          </TableCell>
        ))
      }

      <TableCell align="left">{row.total}</TableCell>




      {/* <TableCell align="center">
        <Iconify
          icon={isVerified ? 'eva:checkmark-circle-fill' : 'eva:clock-outline'}
          sx={{
            width: 20,
            height: 20,
            color: 'success.main',
            ...(!isVerified && { color: 'warning.main' }),
          }}
        />
      </TableCell> */}


    </TableRow>
  );
}
