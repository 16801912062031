import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import DashboardLayout from '../layouts/dashboard';
import GuestGuard from '../guards/GuestGuard';
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from 'src/guards/AuthGuard';
import Ranges from 'src/pages/ranges/Ranges';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard" />,
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },

    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/arbety" replace />, index: true },
        { path: 'arbety', element: <Arbety /> },
        { path: 'limits', element: <Limits /> },
        { path: 'ranges', element: <Ranges /> },
      ],
    },
  ]);
}

const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Arbety = Loadable(lazy(() => import('../pages/casas/Arbety')));
const Limits = Loadable(lazy(() => import('../pages/limits/Limits')));

